<template>
    <div class="row">
        <section class="section about-area ptb_100">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-12 col-lg-6">
                        <!-- About Thumb -->
                        <div class="about-thumb text-center">
                            <img src="assets/img/videoPlatform.png" alt="live class and white board mobile app for schools coaching centers">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <!-- About Content -->
                        <div class="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                            <h2 class="mb-3">What is video platform solution ?</h2>
                            <p></p><br>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-7">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2>We provide the best ERP services</h2>
                        <!-- <p class="d-none d-sm-block mt-4"></p>
                        <p class="d-block d-sm-none mt-4"></p> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-smartphone color-4 icon-bg-4"></span>
                        <h3 class="my-3">Video Storage</h3>
                        <p>Single Mobile app for Admin, Staff, Parents, Push notifications, homework, live classes.</p>
                        <!-- <a class="service-btn mt-3" href="/WhyUs" title="Single Mobile app for Admin, Staff, Parents">Read More</a> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-monitoring color-2 icon-bg-2"></span>
                        <h3 class="my-3">Live Classes</h3>
                        <p>Online Exams, instant Result and reports, manage marks and report card generation.</p>
                        <!-- <a class="service-btn mt-3" href="/WhyUs" title="Push notifications, homework, live classes">Read More</a> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-rocket-launch color-1 icon-bg-1"></span>
                        <h3 class="my-3">White Board</h3>
                        <p>Fee Collection, Payments, Reports and Ledger Generation.</p>
                        <!-- <a class="service-btn mt-3" href="/WhyUs" title="Fee Collection, Payments, Reports and Ledger Generation.">Read More</a> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-web color-3 icon-bg-3"></span>
                        <h3 class="my-3">File Sharing</h3>
                        <p>Generate Dynamic Reports on a single click with user chosen filters and columns.</p>
                        <!-- <a class="service-btn mt-3" href="/WhyUs" title="generate dynamic reports on sigle click in this School erp system">Read More</a> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-email color-5 icon-bg-5"></span>
                        <h3 class="my-3">Auto Attendance</h3>
                        <p>Leave application and approvals, Generate Salary slips, ESI and tax calculations.</p>
                        <!-- <a class="service-btn mt-3" href="/WhyUs" title="leave application, leave approvals, salary slip generation, esi and tax calculations are very easy School Mobile Application">Read More</a> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-affiliate color-6 icon-bg-6"></span>
                        <h3 class="my-3">Live Tracking</h3>
                        <p>Attendance on mobile app or biometric machines, Attendance Reports.</p>
                        <!-- <a class="service-btn mt-3" href="/WhyUs" title="attendance on vidyatra mobile app, desktop and by biometric is possible in this vidyatra erp software and also you will get reports of those">Read More</a> -->
                    </div>
                </div>
            </div>
        </div>
        <section class="ptb_100 row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog res-margin">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/videoStorage.jpeg" alt="live video storage for schools and coaching centers" style="min-height:200px;max-height:200px;min-width:100%;"></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <!-- <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">Anna Sword</a></li>
                            <li><a href="#">Feb 05, 2020</a></li>
                        </ul> -->
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Video Storage</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                        <a href="#" class="blog-btn mt-3">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog res-margin">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/liveClass.jpg" alt="live classes" style="min-height:200px;max-height:200px;min-width:100%;"></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <!-- <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">Jassica William</a></li>
                            <li><a href="#">Feb 05, 2020</a></li>
                        </ul> -->
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Live Classes</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                        <a href="#" class="blog-btn mt-3">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/attendanceTracking.png" alt="live class tracking mobile app" style="min-height:200px;max-height:200px;min-width:100%;"></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <!-- <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">John Doe</a></li>
                            <li><a href="#">Mar 05, 2020</a></li>
                        </ul> -->
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Attendance & Tracking</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                        <a href="#" class="blog-btn mt-3">Read More</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>