<template>
    <section class="section promo-area ptb_100">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center" style="padding:60px;">
                    <h3 style="font-size:34px;">Setting Up a Software for Your School Has Never Been Easier</h3>
                </div>
                <div class="col-12 col-md-6 col-lg-4 res-margin">
                    <!-- Single Promo -->
                    <div class="single-promo color-1 bg-hover hover-bottom p-5 text-left">
                        <h4 class="mb-3">Step 1</h4>
                        <h6>Connect to us over phone <br><a href="/contact">7027-03-7027</a> or drop us an <br> email. <a href="/contact">mail@vidyatra.com</a></h6>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 res-margin">
                    <!-- Single Promo -->
                    <div class="single-promo color-2 bg-hover active hover-bottom p-5 text-left">
                        <h4 class="mb-3">Step 2</h4>
                        <h6>Get the Best ERP for your School within a few hours.</h6><br>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Promo -->
                    <div class="single-promo color-3 bg-hover hover-bottom p-5 text-left">
                        <h4 class="mb-3">Step 3</h4>
                        <h6>Start managing your School with a few clicks.</h6><br>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>