<template>
    <section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
        <div class="container">
            <div class="row align-items-center">
                <!-- Welcome Intro Start -->
                <div class="col-12 col-md-6">
                    <div class="welcome-intro">
                        <h2 class="text-white text-center text-md-left" style="font-size:50px;">AI Enabled ERP <br> <span class="fw-4">saves 40% time</span><br><span class="fw-4" style="font-size:40px;">with 100% security</span></h2>
                        <p class="text-white text-center text-md-left my-4">We Have Been Working Since 2010</p>
                        <!-- Buttons -->
                        <div class="button-group d-flex align-items-center justify-content-center justify-content-md-start">
                            <a href="/contact" class="btn btn-bordered-white">Start Free Trial</a>
                            <!-- Play Button -->
                            <a class="play-btn" data-fancybox data-width="640" data-height="360" data-small-btn="true" href="#">
                                <div class="btn-circle play-animation"></div>
                                <div class="btn-circle play-animation animation-short"></div>
                                <!-- Play Icon -->
                                <div class="play-icon">
                                    <i class="fas fa-play"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 col-md-6">
                    -- Welcome Thumb --
                    <div class="welcome-thumb-wrapper pt-3 pt-md-5 mt-5">
                        <span class="welcome-thumb-1">
                            <img class="welcome-animation d-block ml-auto" src="assets/img/welcome_thumb_7.png" alt="">
                        </span>
                        <span class="welcome-thumb-2">
                            <img class="welcome-animation d-block" src="assets/img/welcome_thumb_8.png" alt="">
                        </span>
                        <span class="welcome-thumb-3">
                            <img class="welcome-animation d-block" src="assets/img/welcome_thumb_9.png" alt="">
                        </span>
                        <span class="welcome-thumb-4">
                            <img class="welcome-animation d-block" src="assets/img/welcome_thumb_10.png" alt="">
                        </span>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- Shape Bottom -->
        <div class="welcome-shape">
            <img src="assets/img/hero_shape.png" alt="">
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>