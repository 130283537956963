<template>
    <section id="pricing" class="section price-plan-area overflow-hidden ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-12">
                    <div class="row price-plan-wrapper">
                        <div class="col-12 col-md-4">
                            <!-- Single Price Plan -->
                            <div class="single-price-plan color-1 bg-hover hover-top text-center p-5">
                                <!-- Plan Title -->
                                <div class="plan-title mb-2 mb-sm-3">
                                    <h3 class="mb-2">Monthly</h3>
                                    <p></p>
                                </div>
                                <!-- Plan Price -->
                                <div class="plan-price pb-2 pb-sm-3">
                                    <span class="color-primary fw-7"></span>
                                    <span class="h1 fw-7" style="color:white;">N/A</span>
                                    <sub class="validity text-muted fw-5"></sub>
                                </div>
                                <!-- Plan Description -->
                                <div class="plan-description">
                                    <ul class="plan-features">
                                        <li class="py-2">30 Days Validity</li>
                                        <li class="py-2">24 Paisa / SMS</li>
                                        <li class="py-2">Online Support</li>
                                        <li class="py-2">Online Training</li>
                                    </ul>
                                </div>
                                <!-- Plan Button -->
                                <div class="plan-button">
                                    <a href="/contact" class="btn btn-bordered mt-3" title="contact vidyatra for erp monthly plan pricing and purchasing">Get Started</a>
                                </div>
                            </div>
                        </div>
                    <div class="col-12 col-md-4 mt-4 mt-md-0">
                        <!-- Single Price Plan -->
                            <div class="single-price-plan color-1 bg-hover hover-top text-center p-5">
                                <!-- Plan Title -->
                                <div class="plan-title mb-2 mb-sm-3">
                                    <h3 class="mb-2">Bi-Annual<sup><span class="badge badge-pill badge-warning ml-2">10% Extra</span></sup></h3>
                                    <p></p>
                                </div>
                                <!-- Plan Price -->
                                <div class="plan-price pb-2 pb-sm-3">
                                    <span class="color-primary fw-7"></span>
                                    <span class="h1 fw-7">18</span>
                                    <sub class="validity text-muted fw-5"> Extra Days</sub>
                                </div>
                                <!-- Plan Description -->
                                <div class="plan-description">
                                    <ul class="plan-features">
                                        <li class="py-2">180 + 18 Days Validity</li>
                                        <li class="py-2">24 Paisa / SMS</li>
                                        <li class="py-2">Online Support</li>
                                        <li class="py-2">Online Training</li>
                                    </ul>
                                </div>
                                <!-- Plan Button -->
                                <div class="plan-button">
                                    <a href="/contact" class="btn btn-bordered mt-3" title="contact vidyatra for erp bi-annual plan pricing and purchasing">Get Started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 mt-4 mt-md-0">
                            <!-- Single Price Plan -->
                            <div class="single-price-plan color-2 bg-hover active hover-top text-center p-5">
                                <!-- Plan Title -->
                                <div class="plan-title mb-2 mb-sm-3">
                                    <h3 class="mb-2">Annual<sup><span class="badge badge-pill badge-warning ml-2">20% Extra</span></sup></h3>
                                    <p></p>
                                </div>
                                <!-- Plan Price -->
                                <div class="plan-price pb-2 pb-sm-3">
                                    <span class="color-primary fw-7"></span>
                                    <span class="h1 fw-7">72</span>
                                    <sub class="validity text-muted fw-5"> Extra Days</sub>
                                </div>
                                <!-- Plan Description -->
                                <div class="plan-description">
                                    <ul class="plan-features">
                                        <li class="py-2">360 + 72 Days Validity</li>
                                        <li class="py-2">24 Paisa / SMS</li>
                                        <li class="py-2">Onsite Support*</li>
                                        <li class="py-2">Online Training</li>
                                    </ul>
                                </div>
                                <!-- Plan Button -->
                                <div class="plan-button">
                                    <a href="/contact" class="btn btn-bordered mt-3" title="contact vidyatra for erp yearly plan pricing and purchasing">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center pt-5">
                <div class="col-12">
                    <!-- FAQ Content -->
                    <div class="faq-content">
                        <!-- sApp Accordion -->
                        <div class="accordion" id="sApp-accordion">
                            <div class="row justify-content-center">
                                <div class="col-12 col-md-6">
                                    <!-- Single Card -->
                                    <div class="card border-0">
                                        <!-- Card Header -->
                                        <div class="card-header bg-inherit border-0 p-0">
                                            <h2 class="mb-0">
                                                <button class="btn px-0 py-3" type="button">
                                                    How Payment is made?
                                                </button>
                                            </h2>
                                        </div>
                                        <!-- Card Body -->
                                        <div class="card-body px-0 py-3">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <!-- Single Card -->
                                    <div class="card border-0">
                                        <!-- Card Header -->
                                        <div class="card-header bg-inherit border-0 p-0">
                                            <h2 class="mb-0">
                                                <button class="btn px-0 py-3" type="button">
                                                    What is Extra Days?
                                                </button>
                                            </h2>
                                        </div>
                                        <!-- Card Body -->
                                        <div class="card-body px-0 py-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <p class="text-body text-center pt-4 fw-5">Haven't find suitable answer? <a href="#">Tell us what you need.</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>