<template>
    <section class="section about-area ptb_100">
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-12 col-lg-6">
                    <!-- About Thumb -->
                    <div class="about-thumb text-center">
                        <img src="assets/img/about_vidyatra.jpg" alt="about vidyatra school management software, about vidyatra mobile application">
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- About Content -->
                    <div class="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                        <h2 class="mb-3">We’re Your Partner in Your Success</h2>
                        <p></p><br>
                        <p></p>
                        <!-- Counter Area -->
                        <div class="couter-area mt-5">
                            <!-- Single Counter -->
                            <div class="single-counter d-block d-md-inline-block m-4 m-md-2">
                                <div class="counter-inner">
                                    <!-- Counter Item -->
                                    <div class="counter-item d-inline-block mb-3">
                                        <span class="counter color-1 fw-7">14</span><span class="color-1 fw-7">+</span>
                                    </div>
                                </div>
                                <h4 class="text-uppercase fw-7">States</h4>
                            </div>
                            <!-- Single Counter -->
                            <div class="single-counter d-block d-md-inline-block m-4 m-md-2">
                                <div class="counter-inner">
                                    <!-- Counter Item -->
                                    <div class="counter-item d-inline-block mb-3">
                                        <span class="counter color-2 fw-7">850</span><span class="color-2 fw-7">+</span>
                                    </div>
                                </div>
                                <h4 class="text-uppercase fw-7">Schools</h4>
                            </div>
                            <!-- Single Counter -->
                            <div class="single-counter d-block d-md-inline-block m-4 m-md-2">
                                <div class="counter-inner">
                                    <!-- Counter Item -->
                                    <div class="counter-item d-inline-block mb-3">
                                        <span class="counter color-3 fw-7">450</span><span class="color-3 fw-7">+</span>
                                    </div>
                                </div>
                                <h4 class="text-uppercase fw-7">Coaching Centers</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>