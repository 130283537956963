<template>
    <article class="single-blog-details">
        <!-- Blog Content -->
        <div class="blog-content digimax-blog p-3">
            <!-- Meta Info -->
            <div class="meta-info d-flex flex-wrap align-items-center py-2">
                <ul>
                    <li class="d-inline-block p-2"><a href="#">Vidyatra</a></li>
                    <li class="d-none d-md-inline-block p-2"><a href="#">05 Feb, 2020</a></li>
                </ul>
            </div>
            <!-- Blog Details -->
            <div class="blog-details">
                <h6 class="blog-title py-3"><a href="#">Service Offered</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>The services of this website Vidyatra are available only to the registered users on single user basis, for a particular period of time on making the stipulated payment and abiding by the applicable terms and conditions. The said services are personal in nature and cannot be assigned or transferred to or shared with any other person other than the registered users.
                        By registering yourself as a user and subscribing to avail the services of this website Vidyatra it will be deemed that you have read, understood and agreed to fully abide by all the terms and conditions of the website as contained herein.
                        The right to use the services of the website Vidyatra is on revocable license / permission basis as per the terms and conditions contained herein. Except the availment of the services during the license period, the registered users shall not have any right or title over the website or any of its contents.
                        The services offered by Vidyatra through this site are listed in detail in the home page of this site.
                        Vidyatra currently provides users with access to a rich collection/compilation of online educational information and related resources etc. As a user of this site, you understand and agree that all the services provided are on the basis of as-it-is and Vidyatra assume no liability for the accuracy or completeness or use of, nor any liability to update, the information contained on this website.
                        In order to use Vidyatra online services, you must obtain access to the World Wide Web, either directly or through devices that access web-based content, and pay any service fees associated with such access. In addition, you must provide all equipment necessary to make such a connection to the World Wide Web, including a computer and modem or other access devices.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">User Obligations</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>In consideration of your use of the services, you agree to: provide correct, precise, upto date and complete information about yourself as required by the service's registration form wherever necessary; and maintain and promptly update the registration data to keep it true, accurate, current and complete.
                        If you provide any information that is untrue, inaccurate, not up to date or incomplete, or Vidyatra has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Vidyatra has the right to suspend or terminate your account and refuse any and all current or future use of the services (or any portion thereof).
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Agreement</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>You agree and are hereby put on notice, that you are prohibited from posting or transmitting to this website, any content/material that in unlawful, threatening, libelous, defamatory, pornographic or that in any manner violate or infringe the proprietary rights, intellectual property rights or any right or privacy of third party whatsoever and that violate any law, statute, ordinance or regulation; and that contain viruses or other similar harmful or threatening deleterious programming routines. If you violate this provision you will be finalised apart from termination of your rights to use the services and any other remedies available to Vidyatra
                        Software (if any) that is made available to download from the Vidyatra site, excluding software that may be made available by end-users through a Communication Service, (Software) is the copyrighted work of the software provider. Your use of the Software is governed by the terms of the end user License Agreement unless you first agree to the License Agreement terms.
                        Vidyatra reserves the right, in its sole discretion, to terminate or refuse all or part of its services, for any reason without notice to you.
                        This Agreement shall be governed by the Laws in India and in all disputes arising out of or relating to the use of Vidyatra, you hereby consent to the exclusive jurisdiction and venue of courts in Mumbai, India (only).
                        This Agreement does not in any manner create any relationship whatsoever as between you and the Vidyatra , either as a joint venture, partnership, employment, or agency relationship. Performance of this agreement by Vidyatra is subject to existing laws and legal process in India, and nothing contained in this agreement is in derogation of the rights of Vidyatra to comply with governmental, court and law enforcement requests or requirements relating to your use of Vidyatra or information provided to or gathered by Vidyatra with respect to such use.
                        Unless otherwise specified herein, this Agreement constitutes the entire agreement between the user and Vidyatra with respect to the Vidyatra and it supersedes all prior communications and proposals, whether electronic, oral or written, between the user and Vidyatra with respect to the Vidyatra site.
                    </p>
                </blockquote>
            </div>
        </div>
    </article>
</template>

<script>
export default {

}
</script>

<style>

</style>