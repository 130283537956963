<template>
    <div class="row">
        <section class="section about-area ptb_100">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-12 col-lg-6">
                        <!-- About Thumb -->
                        <div class="about-thumb text-center">
                            <img src="assets/img/tollFree.jpg" alt="Toll Free Number Service">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <!-- About Content -->
                        <div class="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                            <h2 class="mb-3">What is a Toll Free Number Solution?</h2>
                            <p>Your customers can reach out to you for free on your 1800 business toll free number. Ensure seamless interaction between the agents and customers for query resolution, upcoming promotions or events.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2>Benefits of Toll Free Number</h2>
                        <p class="d-none d-sm-block mt-4">Boost your services and products efficiently with Toll free number campaigns.</p>
                        <!-- <p class="d-block d-sm-none mt-4"></p> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-12 col-md-6 col-lg-4">
                    Single Service
                    <div class="single-service p-4">
                        <span class="flaticon-smartphone color-4 icon-bg-4"></span>
                        <h3 class="my-3">Video Storage</h3>
                        <p>Single Mobile app for Admin, Staff, Parents, Push notifications, homework, live classes.</p>
                        <a class="service-btn mt-3" href="/WhyUs" title="Single Mobile app for Admin, Staff, Parents">Read More</a>
                    </div>
                </div> -->
                <!-- <div class="col-12 col-md-6 col-lg-4">
                    <div class="single-blog">
                        <div class="single-service p-4">
                            <span class="flaticon-smartphone color-4 icon-bg-4"></span>
                            <h3 class="my-3">Video Storage</h3>
                            <p>Single Mobile app for Admin, Staff, Parents, Push notifications, homework, live classes.</p>
                            <a class="service-btn mt-3" href="/WhyUs" title="Single Mobile app for Admin, Staff, Parents">Read More</a>
                        </div>
                    </div>
                </div> -->
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-monitoring color-2 icon-bg-2"></span>
                        <h3 class="my-3">Increase Lead Conversion</h3>
                        <p>Toll free numbers drive greater call rates, which results in significant lead conversion rates for businesses.</p>
                        <!-- <a class="service-btn mt-3" href="/WhyUs" title="Push notifications, homework, live classes">Read More</a> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-rocket-launch color-1 icon-bg-1"></span>
                        <h3 class="my-3">Build Brand Recall Value</h3>
                        <p>1800 toll free numbers are easier to recall, enabling customers to engage effortlessly across multi-touchpoint campaigns.</p>
                        <!-- <a class="service-btn mt-3" href="/WhyUs" title="Fee Collection, Payments, Reports and Ledger Generation.">Read More</a> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-web color-3 icon-bg-3"></span>
                        <h3 class="my-3">Boost Customer Satisfaction</h3>
                        <p>Customers bear zero cost when reaching out to you, giving them more options for query resolution and grievance addressing.</p>
                        <!-- <a class="service-btn mt-3" href="/WhyUs" title="generate dynamic reports on sigle click in this School erp system">Read More</a> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-email color-5 icon-bg-5"></span>
                        <h3 class="my-3">Generate Business in New Markets</h3>
                        <p>Expand your customer service reach across the country without on-site infrastructure investment through premium toll free numbers.</p>
                        <!-- <a class="service-btn mt-3" href="/WhyUs" title="leave application, leave approvals, salary slip generation, esi and tax calculations are very easy School Mobile Application">Read More</a> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-affiliate color-6 icon-bg-6"></span>
                        <h3 class="my-3">Track Marketing Campaigns</h3>
                        <p>Seamlessly track marketing campaign effectiveness. Collect customer feedback and surveys instantly through toll free numbers.</p>
                        <!-- <a class="service-btn mt-3" href="/WhyUs" title="attendance on vidyatra mobile app, desktop and by biometric is possible in this vidyatra erp software and also you will get reports of those">Read More</a> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-affiliate color-6 icon-bg-6"></span>
                        <h3 class="my-3">Establish 24 x7 Availability</h3>
                        <p>With toll free business numbers, be available to your customers even after business hours.</p>
                        <!-- <a class="service-btn mt-3" href="/WhyUs" title="attendance on vidyatra mobile app, desktop and by biometric is possible in this vidyatra erp software and also you will get reports of those">Read More</a> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="container ptb_100">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2>Features of Toll Free Number</h2>
                    </div>
                </div>
            </div>
        </div>
        <section class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog res-margin" style="min-height:250px;">
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <!-- <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">Anna Sword</a></li>
                            <li><a href="#">Feb 05, 2020</a></li>
                        </ul> -->
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">IVR</a></h3>
                        <p>Answer every customer query with the IVR’s multi-level menu to establish immediate interaction with your customer.</p>
                        <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog res-margin" style="min-height:250px;">
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <!-- <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">Jassica William</a></li>
                            <li><a href="#">Feb 05, 2020</a></li>
                        </ul> -->
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Call Recording</a></h3>
                        <p>Automatic call recording enables you to listen and analyze them, to understand your customers better.</p>
                        <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog" style="min-height:250px;">
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <!-- <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">John Doe</a></li>
                            <li><a href="#">Mar 05, 2020</a></li>
                        </ul> -->
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Call Routing</a></h3>
                        <p>Call routing enables the available agents to answer the customer calls as the call routes to the free agents to reduce customer wait time.</p>
                        <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>