<template>
    <section id="contact" class="contact-area ptb_100">
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-12 col-lg-5">
                    <!-- Section Heading -->
                    <div class="section-heading text-center mb-3">
                        <h2>Let's connect!</h2>
                        <!-- <p class="d-none d-sm-block mt-4">Like Us On.</p> -->
                        <p class="d-none d-sm-block mt-4">
                        <ul class="social-icons list-inline pt-2" style="font-size:20px;">
                            <li class="list-inline-item px-1"><a href="https://www.facebook.com/VidyatraSystems/" target="_blank" title="vidyatra on facebook"><i class="fab fa-facebook"></i></a></li>
                            <li class="list-inline-item px-1"><a href="https://twitter.com/vidyatra" target="_blank" title="vidyatra on twitter"><i class="fab fa-twitter"></i></a></li>
                            <li class="list-inline-item px-1"><a href="https://www.linkedin.com/company/vidyatra" target="_blank" title="vidyatra on linkedin "><i class="fab fa-linkedin-in"></i></a></li>
                            <li class="list-inline-item px-1"><a href="#" title="vidyatra on instagram"><i class="fab fa-instagram"></i></a></li>
                        </ul>    
                        </p>
                    </div>
                    <!-- Contact Us -->
                    <div class="contact-us">
                        <ul>
                            <!-- Contact Info -->
                            <li class="contact-info color-1 bg-hover active hover-bottom text-center p-5 m-3">
                                <span><i class="fas fa-mobile-alt fa-3x"></i></span>
                                <a class="d-block my-2" href="#" title="vidyatra best school management software haryana rajasthan uttar pradesh bihar uttrakhand customer support number and timings">
                                    <h3>(+91) 7027-03-7027</h3>
                                </a>
                                <p>Mon - Sat : 08:00 AM - 06:00 PM</p>
                            </li>
                            <!-- Contact Info -->
                            <li class="contact-info color-3 bg-hover active hover-bottom text-center p-3 m-3">
                                <span><i class="fas fa-envelope-open-text fa-3x"></i></span>
                                <a class="d-none d-sm-block my-2" href="#" title="vidyatra mobile application support email id for customers">
                                    <h3>mail@vidyatra.com</h3>
                                </a>
                                <a class="d-block d-sm-none my-2" href="#" title="vidyatra support best school management software email id for customers">
                                    <h3>mail@vidyatra.com</h3>
                                </a>
                                <p>Drop Us A Mail To Catch A Break.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-lg-6 pt-4 pt-lg-0">
                    <!-- Contact Box -->
                    <div class="contact-box text-center">
                        <!-- Contact Form -->
                        <form id="contact-form" method="POST" action="assets/php/mail.php">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" name="name" placeholder="Name" required="required">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" name="orgname" placeholder="Organisation Name" required="required">
                                    </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control" name="email" placeholder="Email" required="required">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" name="phone" placeholder="Phone" required="required">
                                    </div>
                                    <!-- <div class="form-group">
                                        <input type="text" class="form-control" name="subject" placeholder="Subject" required="required">
                                    </div> -->
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <textarea class="form-control" name="message" placeholder="Message" required="required"></textarea>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="btn btn-bordered active btn-block mt-3"><span class="text-white pr-3"><i class="fas fa-paper-plane"></i></span>Send Message</button>
                                </div>
                            </div>
                        </form>
                        <p class="form-message"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>