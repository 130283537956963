<template>
    <section class="section cta-area bg-overlay ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10">
                    <!-- Section Heading -->
                    <div class="section-heading text-center m-0">
                        <h3 class="text-white" style="font-size:40px">Download Mobile App</h3>
                        <h6 class="text-white">One Million Success Stories. &amp; Start Yours Today !</h6>
                        <!-- <p class="text-white d-none d-sm-block mt-4"></p>
                        <p class="text-white d-block d-sm-none mt-4"></p> -->
                        <a href="https://play.google.com/store/apps/details?id=com.acadbee.vidyatra" target="_blank" class="btn btn-bordered mt-3" title="Download Vidyatra App on Google Play Store"><span class="pr-3"><i class="fab fa-android"></i></span>Google Play</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>