<template>
    <div class="row">
        <section class="section about-area ptb_100">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-12 col-lg-6">
                        <!-- About Thumb -->
                        <div class="about-thumb text-center">
                            <img src="assets/img/ivr.jpg" alt="IVR service for schools colleges universities and coaching centers">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <!-- About Content -->
                        <div class="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                            <h2 class="mb-3">What is an IVR Solution?</h2>
                            <p>IVR stands for Interactive Voice Response and is an automated Call Management Solution. IVR initiates interaction with customers with a personalized greeting while offering a category of menus to select the desired query.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2>Benefits of IVR Solution</h2>
                        <p class="d-none d-sm-block mt-4">Guide your customers to the right agent, through multi-level category menus assisting them to resolve their queries faster.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-12 col-md-6 col-lg-4">
                    Single Service
                    <div class="single-service p-4">
                        <span class="flaticon-smartphone color-4 icon-bg-4"></span>
                        <h3 class="my-3">Video Storage</h3>
                        <p>Single Mobile app for Admin, Staff, Parents, Push notifications, homework, live classes.</p>
                        <a class="service-btn mt-3" href="/WhyUs" title="Single Mobile app for Admin, Staff, Parents">Read More</a>
                    </div>
                </div> -->
                <!-- <div class="col-12 col-md-6 col-lg-4">
                    <div class="single-blog">
                        <div class="single-service p-4">
                            <span class="flaticon-smartphone color-4 icon-bg-4"></span>
                            <h3 class="my-3">Video Storage</h3>
                            <p>Single Mobile app for Admin, Staff, Parents, Push notifications, homework, live classes.</p>
                            <a class="service-btn mt-3" href="/WhyUs" title="Single Mobile app for Admin, Staff, Parents">Read More</a>
                        </div>
                    </div>
                </div> -->
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-monitoring color-2 icon-bg-2"></span>
                        <h3 class="my-3">Generate More Leads</h3>
                        <p>Online Exams, instant Result and reports, manage marks and report card generation.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-rocket-launch color-1 icon-bg-1"></span>
                        <h3 class="my-3">Enhance Brand Image</h3>
                        <p>Fee Collection, Payments, Reports and Ledger Generation.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-web color-3 icon-bg-3"></span>
                        <h3 class="my-3">Manage Large Call Volumes</h3>
                        <p>Generate Dynamic Reports on a single click with user chosen filters and columns.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-email color-5 icon-bg-5"></span>
                        <h3 class="my-3">Offer Personalized Experience</h3>
                        <p>Leave application and approvals, Generate Salary slips, ESI and tax calculations.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-affiliate color-6 icon-bg-6"></span>
                        <h3 class="my-3">Easy to Set-up and Activate</h3>
                        <p>Attendance on mobile app or biometric machines, Attendance Reports.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-affiliate color-6 icon-bg-6"></span>
                        <h3 class="my-3">Be Omnipresent</h3>
                        <p>Attendance on mobile app or biometric machines, Attendance Reports.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container ptb_100">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2>Features of IVR Solution</h2>
                    </div>
                </div>
            </div>
        </div>
        <section class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog res-margin" style="min-height:250px;">
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Multi-lingual Support</a></h3>
                        <p>Offer multilingual support to customers with customized English, Hindi and regional custom messages.</p>
                        <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog res-margin" style="min-height:250px;">
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">24 x7 Customer Support</a></h3>
                        <p>Offer 24x7 uninterrupted support to your customers via record messages and provide instant query resolvement.</p>
                        <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog" style="min-height:250px;">
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Call Routing</a></h3>
                        <p>Call routing enables the available agents to answer the customer calls as the call routes to the free agents to reduce customer wait time.</p>
                        <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog res-margin" style="min-height:250px;">
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Customizable Menu</a></h3>
                        <p>Customize the call flow with a menu categorized along with several sub-menu options to help your customers navigate their queries.</p>
                        <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog res-margin" style="min-height:250px;">
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Concurrent Calls</a></h3>
                        <p>Respond to an unlimited number of concurrent inbound calls at a time to reduce customer wait time.</p>
                        <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog" style="min-height:250px;">
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Call Recording</a></h3>
                        <p>Record every call in real-time to listen to them later and maintain quality standards.</p>
                        <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>