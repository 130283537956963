<template>
    <section id="pricing" class="section price-plan-area bg-grey overflow-hidden ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-7">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2>ERP Plans</h2>
                        <!-- <h5 class="d-none d-sm-block mt-4">#rechargeWhenYouNeed</h5> -->
                        <!-- <p class="d-block d-sm-none mt-4"></p> -->
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12 col-lg-12">
                    <div class="row price-plan-wrapper">
                        <div class="col-12 col-md-3">
                            <!-- Single Price Plan -->
                            <div class="single-price-plan color-2 active bg-hover hover-top text-center p-5">
                                <!-- Plan Title -->
                                <div class="plan-title mb-2 mb-sm-3">
                                    <h3 class="mb-2">Basic</h3>
                                    <p></p>
                                </div>
                                <!-- Plan Price -->
                                <div class="pb-2 pb-sm-3">
                                    <span class="color-primary fw-7"></span>
                                    <span class="h6 fw-7">3999/- + 18 % GST</span>
                                    <sub class="validity text-muted fw-5"></sub>
                                </div>
                                <!-- Plan Description -->
                                <div class="plan-description">
                                    <ul class="plan-features">
                                        <li class="py-2">ERP System</li>
                                        <li class="py-2">24 Paisa / SMS</li>
                                        <li class="py-2">Online Support</li>
                                        <li class="py-2">Online Training</li>
                                    </ul>
                                </div>
                                <!-- Plan Button -->
                                <div class="plan-button">
                                    <a href="/contact" class="btn btn-bordered mt-3" title="contact vidyatra school management software for erp monthly plan pricing and purchasing">Get Started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <!-- Single Price Plan -->
                            <div class="single-price-plan color-1 active bg-hover hover-top text-center p-5">
                                <!-- Plan Title -->
                                <div class="plan-title mb-2 mb-sm-3">
                                    <h3 class="mb-2">Advance</h3>
                                    <p></p>
                                </div>
                                <!-- Plan Price -->
                                <div class="pb-2 pb-sm-3">
                                    <!-- <span class="color-primary fw-7"></span> -->
                                    <span class="h6 fw-7">5999/- + 18 % GST</span>
                                    <!-- <sub class="validity text-muted fw-5"></sub> -->
                                </div>
                                <!-- Plan Description -->
                                <div class="plan-description">
                                    <ul class="plan-features">
                                        <li class="py-2">Basic +</li>
                                        <li class="py-2">Video Platform*</li>
                                        <li class="py-2">Online Support</li>
                                        <li class="py-2">Online Training</li>
                                    </ul>
                                </div>
                                <!-- Plan Button -->
                                <div class="plan-button">
                                    <a href="/contact" class="btn btn-bordered mt-3" title="contact vidyatra school management software for erp monthly plan pricing and purchasing">Get Started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mt-3 mt-md-0">
                        <!-- Single Price Plan -->
                            <div class="single-price-plan color-6 active bg-hover hover-top text-center p-5">
                                <!-- Plan Title -->
                                <div class="plan-title mb-2 mb-sm-3">
                                    <h3 class="mb-2">Professional<sup><span class="badge badge-pill badge-warning ml-2"></span></sup></h3>
                                    <p></p>
                                </div>
                                <!-- Plan Price -->
                                <div class="pb-2 pb-sm-3">
                                    <!-- <span class="color-primary fw-7"></span> -->
                                    <span class="h6 fw-7">9999/- + 18 % GST</span>
                                    <!-- <sub class="validity text-muted fw-5"></sub> -->
                                </div>
                                <!-- Plan Description -->
                                <div class="plan-description">
                                    <ul class="plan-features">
                                        <li class="py-2">Advance +</li>
                                        <li class="py-2">IVR System</li>
                                        <li class="py-2">Chat Bot</li>
                                        <li class="py-2">Onsite Support</li>
                                        <li class="py-2">Onsite Training</li>
                                    </ul>
                                </div>
                                <!-- Plan Button -->
                                <div class="plan-button">
                                    <a href="/contact" class="btn btn-bordered mt-3" title="contact vidyatra erp software for erp bi-annual plan pricing and purchasing">Get Started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mt-3 mt-md-0">
                            <!-- Single Price Plan -->
                            <div class="single-price-plan color-3 bg-hover active hover-top text-center p-5">
                                <!-- Plan Title -->
                                <div class="plan-title mb-2 mb-sm-3">
                                    <h3 class="mb-2">Business<sup><span class="badge badge-pill badge-warning ml-2"></span></sup></h3>
                                    <p></p>
                                </div>
                                <!-- Plan Price -->
                                <div class="pb-2 pb-sm-3">
                                    <!-- <span class="color-primary fw-7"></span> -->
                                    <span class="h6 fw-7">12999/- + 18 % GST</span>
                                    <!-- <sub class="validity text-muted fw-5"></sub> -->
                                </div>
                                <!-- Plan Description -->
                                <div class="plan-description">
                                    <ul class="plan-features">
                                        <li class="py-2">Professional +</li>
                                        <li class="py-2">Toll Free Number</li>
                                        <li class="py-2">Missed Call Service</li>
                                        <li class="py-2">Onsite Support</li>
                                        <li class="py-2">Onsite Training</li>
                                    </ul>
                                </div>
                                <!-- Plan Button -->
                                <div class="plan-button">
                                    <a href="/contact" class="btn btn-bordered mt-3" title="contact vidyatra mobile application for erp yearly plan pricing and purchasing">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center pt-5">
                <p class="pt-4 fw-5">Not sure what to choose? <a class="service-btn" href="/contact" title="if you are not sure about what to purchase please contact vidyatra school managemnt software"><strong>Contact Us</strong></a></p>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>