<template>
    <article class="single-blog-details">
        <!-- Blog Content -->
        <div class="blog-content digimax-blog p-3">
            <!-- Meta Info -->
            <div class="meta-info d-flex flex-wrap align-items-center py-2">
                <ul>
                    <li class="d-inline-block p-2"><a href="#">Vidyatra</a></li>
                    <li class="d-none d-md-inline-block p-2"><a href="#">05 Feb, 2020</a></li>
                </ul>
            </div>
            <!-- Blog Details -->
            <div class="blog-details">
                <h6 class="blog-title py-3"><a href="#">Personal Information</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>
                        This Privacy Policy governs the manner in which the Service Provider collects, uses, maintains and discloses information collected from users (each, a "User") of the “www.vidyatra.com” website ("Site"). This privacy policy applies to the Site and all products and services offered by the Service Provider. This Privacy Policy applies to current and former visitors to our website and to our online customers.
                        By visiting and/or using the Site, Users agree to this Privacy Policy.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Personal informational</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>We may collect personal information from Users in different forms. The School admin will put up all the information of students and teachers as per need. We will also ask information for guidance and support messages, for app usage, for registration and many other services. We will collect the information like name, gender, email address, phone number and organization details.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Non-personal information</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>We may collect non-personal identification information about user’s usage of our website. This may include the browser name, date and time of visit, IP Address and technical information about Users means of connection to our Site and the Internet service providers utilized and other similar information.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Security of information</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>Data security is one of our prime aims to retain privacy for our customers. We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password,
                        transaction information and data stored on our site. We will not misuse or sell school data to any third party. Only very limited information like number of students etc. will be used for internal functioning of the company.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Usage of information</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>The Service Provider ensures that any information collected remains private and confidential. We will never use, sell, rent, exchange or share your information with any third parties. We may collect and use personal information of users for the following purposes:
                        Information provided by our users will help us respond to your customer service requests and support needs more efficiently. To understand the usage of our services by the User. We may use feedback you provide to improve our products and services. To send newsletters and emails about products. To process payments
                        We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. User can unsubscribe whenever he/she wants.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Sharing of information</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>We do not sell, trade or rent Users personal information. Your security is our duty. We may share
                        generic information not linked to any personal identification information regarding visitors and users with our business partners, 
                        trusted affiliates and advertisers for the purposes outlined above. We may use third party service providers to help us operate our
                        business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information
                        with these third parties for those limited purposes provided that you have given us your permission.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Cookies</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>Our website may use cookies, which are small data files that are used as unique identifiers. 
                        They are sent from the website’s servers to your computer or mobile phone and stored on your device to record information about your preferences as you browse our website, 
                        which allows us to improve your experience by tailoring the website accordingly. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent.
                        If they do so, note that some parts of the Site may not function properly.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Website/App policy</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>Here Website refers to ‘vidyatra.com’ website and ‘Vidyatra’ and ‘Vidyatra teacher’ apps
                        The content of the pages of Website is for your general information only. It is subject to change without notice. Neither we nor
                        any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of
                        the information. Your use of any information or materials on Website is entirely at your own risk, for which we shall not be liable.
                        It will be your own responsibility to ensure that any products, services or information available through this website meet your 
                        specific requirements. You are responsible for maintaining the security of your account and password. Vidyatra cannot and will not
                        be liable for any loss or damage from your failure to comply with this security obligation. Unauthorized use of Website may give rise
                        to a claim for damages and/or be a criminal offence. From time to time Website may also include links to other websites.These links are
                        provided for your convenience to provide further information. Tour website may use cookies, which are small data files that are used as unique identifiers.
                        They are sent from the website’s servers to your computer or mobile phone and stored on your device to record information about your
                        preferences as you browse our website, which allows us to improve your experience by tailoring the website accordingly. User may choose
                        to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site
                        may not function properly.hey do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
                        links to other websites. We are not responsible for the privacy practices of such websites, which we do not manage and control.
                    </p>
                </blockquote>

            </div>
        </div>
    </article>
</template>

<script>
export default {

}
</script>

<style>

</style>