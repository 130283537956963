<template>
    <section class="section map-area">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28029.449657960762!2d77.04674638827744!3d28.57933412606684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1ba485e58615%3A0x1b81813fad167780!2sRamphal%20Chowk!5e0!3m2!1sen!2sin!4v1624468579522!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.596666220624!2d-0.16124461362595294!3d51.46556134684942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487605a25375dfb7%3A0xe0d9fa09dcf932a8!2s15%20Theatre%20St%2C%20Battersea%2C%20London%20SW11%205ND%2C%20UK!5e0!3m2!1sen!2sbd!4v1567427969685!5m2!1sen!2sbd" width="100" height="100" style="border:0;" allowfullscreen=""></iframe> -->
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>