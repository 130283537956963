<template>
    <section id="review" class="section review-area bg-overlay ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-7">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2 class="text-white">Our clients says</h2>
                        <!-- <p class="text-white d-none d-sm-block mt-4"></p>
                        <p class="text-white d-block d-sm-none mt-4"></p> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Client Reviews -->
                <div class="client-reviews owl-carousel">
                    <!-- Single Review -->
                    <div class="single-review p-5">
                        <!-- Review Content -->
                        <div class="review-content">
                            <!-- Review Text -->
                            <div class="review-text">
                                <p>Vidyatra Mobile app is super easy to use for our staff, students and parents.
                                   It helped us a lot to conduct online classes and share homework with students during COVID-19.</p>
                            </div>
                            <!-- Quotation Icon -->
                            <div class="quot-icon">
                                <img class="avatar-sm" src="assets/img/quote.png" alt="vidyatra mobile application reviews">
                            </div>
                        </div>
                        <!-- Reviewer -->
                        <div class="reviewer media mt-3">
                            <!-- Reviewer Thumb -->
                            <div class="reviewer-thumb">
                                <img class="avatar-lg radius-100" src="assets/img/avatar_3.png" alt="vidyatra erp system review from haryana, rajasthan, uttar pradesh, uttrakhand, bihar">
                            </div>
                            <!-- Reviewer Media -->
                            <div class="reviewer-meta media-body align-self-center ml-4">
                                <h5 class="reviewer-name color-primary mb-2">Parveen Kumar</h5>
                                <h6 class="text-secondary fw-6">Principal, DPS</h6>
                            </div>
                        </div>
                    </div>
                    <!-- Single Review -->
                    <div class="single-review p-5">
                        <!-- Review Content -->
                        <div class="review-content">
                            <!-- Review Text -->
                            <div class="review-text">
                                <p>Vidyatra report generation is an absolute must have. We can choose columns, 
                                   filters and sorting according to our needs. It is like  having thousands of reports just one click away.</p>
                            </div>
                            <!-- Quotation Icon -->
                            <div class="quot-icon">
                                <img class="avatar-sm" src="assets/img/quote.png" alt="vidyatra best mobile app user reviews">
                            </div>
                        </div>
                        <!-- Reviewer -->
                        <div class="reviewer media mt-3">
                            <!-- Reviewer Thumb -->
                            <div class="reviewer-thumb">
                                <img class="avatar-lg radius-100" src="assets/img/avatar_3.png" alt="vidyatra best school management software for school reviews">
                            </div>
                            <!-- Reviewer Media -->
                            <div class="reviewer-meta media-body align-self-center ml-4">
                                <h5 class="reviewer-name color-primary mb-2">G Sandhu</h5>
                                <h6 class="text-secondary fw-6">Clerk, SRSM</h6>
                            </div>
                        </div>
                    </div>
                    <!-- Single Review -->
                    <div class="single-review p-5">
                        <!-- Review Content -->
                        <div class="review-content">
                            <!-- Review Text -->
                            <div class="review-text">
                                <p>The Vidyatra app helps us to manage all our branches from a single login with few clicks. 
                                   We can check fee collection, student and staff attendance, reports from our different branches from one place.</p>
                            </div>
                            <!-- Quotation Icon -->
                            <div class="quot-icon">
                                <img class="avatar-sm" src="assets/img/quote.png" alt="vidyatra top school management software">
                            </div>
                        </div>
                        <!-- Reviewer -->
                        <div class="reviewer media mt-3">
                            <!-- Reviewer Thumb -->
                            <div class="reviewer-thumb">
                                <img class="avatar-lg radius-100" src="assets/img/avatar_3.png" alt="vidyatra best mobile application with all features">
                            </div>
                            <!-- Reviewer Media -->
                            <div class="reviewer-meta media-body align-self-center ml-4">
                                <h5 class="reviewer-name color-primary mb-2">Sandeep Kumar</h5>
                                <h6 class="text-secondary fw-6">Clerk, BSS</h6>
                            </div>
                        </div>
                    </div>
                    <!-- Single Review -->
                    <div class="single-review p-5">
                        <!-- Review Content -->
                        <div class="review-content">
                            <!-- Review Text -->
                            <div class="review-text">
                                <p>Vidyatra ERP helped us in digitising the school. The team was very efficient 
                                   and we were using the product in almost no time. It has been a very smooth and
                                   pleasing transition from paper/registers to digital records.</p>
                            </div>
                            <!-- Quotation Icon -->
                            <div class="quot-icon">
                                <img class="avatar-sm" src="assets/img/quote.png" alt="vidyatra mobile application with live classes">
                            </div>
                        </div>
                        <!-- Reviewer -->
                        <div class="reviewer media mt-3">
                            <!-- Reviewer Thumb -->
                            <div class="reviewer-thumb">
                                <img class="avatar-lg radius-100" src="assets/img/avatar_1.png" alt="vidyatra mobile application for homework , live classes , leave , admin , parents">
                            </div>
                            <!-- Reviewer Media -->
                            <div class="reviewer-meta media-body align-self-center ml-4">
                                <h5 class="reviewer-name color-primary mb-2">Narender Yadav</h5>
                                <h6 class="text-secondary fw-6">Principal, VNS</h6>
                            </div>
                        </div>
                    </div>
                    <!-- Single Review -->
                    <div class="single-review p-5">
                        <!-- Review Content -->
                        <div class="review-content">
                            <!-- Review Text -->
                            <div class="review-text">
                                <p>Vidyatra has made the exam processing so fast and easy. Instead of taking
                                   months to compile the results with full staff, now we can generate report
                                   cards even the next day of exam checking.</p>
                            </div>
                            <!-- Quotation Icon -->
                            <div class="quot-icon">
                                <img class="avatar-sm" src="assets/img/quote.png" alt="vidyatra best school management software customer reviews">
                            </div>
                        </div>
                        <!-- Reviewer -->
                        <div class="reviewer media mt-3">
                            <!-- Reviewer Thumb -->
                            <div class="reviewer-thumb">
                                <img class="avatar-lg radius-100" src="assets/img/avatar_2.png" alt="vidyatra mobile application for parents">
                            </div>
                            <!-- Reviewer Media -->
                            <div class="reviewer-meta media-body align-self-center ml-4">
                                <h5 class="reviewer-name color-primary mb-2">Rajdeep Yadav</h5>
                                <h6 class="text-secondary fw-6">Chairman, NCC</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>