<template>
    <div class="row">
        <section class="section about-area ptb_100">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-12 col-lg-6">
                        <!-- About Thumb -->
                        <div class="about-thumb text-center">
                            <img src="assets/img/missedCall.jpg" alt="missed call service">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <!-- About Content -->
                        <div class="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                            <h2 class="mb-3">What is a Missed Call Solution?</h2>
                            <p>A missed call solution aids businesses to call back their customers who gave missed calls on their business numbers. The call is processed via auto-dialer, connecting the target audience with either an automated IVR message, a voice bot or a live agent.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2>Benefits of Missed Call Solution</h2>
                        <p class="d-none d-sm-block mt-4">Enhance leads by a significant percentage to increase campaign effectiveness, irrespective of the size of the organization.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-monitoring color-2 icon-bg-2"></span>
                        <h3 class="my-3">Increase Lead Generation</h3>
                        <p>Ensure faster quality lead acquisition by receiving prospect details in real-time for engagement.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-rocket-launch color-1 icon-bg-1"></span>
                        <h3 class="my-3">Expand Market Reach</h3>
                        <p>Broaden brand awareness during promotional periods by launching missed call SMS campaigns.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-web color-3 icon-bg-3"></span>
                        <h3 class="my-3">Missed Call Services for Opt-ins</h3>
                        <p>Seek customer confirmation on specific services, product interests efficiently for opt-ins by avoiding legal hassle for DND numbers.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-email color-5 icon-bg-5"></span>
                        <h3 class="my-3">Launch Outbound Calling Campaigns</h3>
                        <p>Combine missed call solution with outbound calling solution processed through targeted messages to customer contact numbers.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-affiliate color-6 icon-bg-6"></span>
                        <h3 class="my-3">Enhance Interactivity for Campaigns</h3>
                        <p>Gather customer feedback, while also calling them back to share product insights and new launch information.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-affiliate color-6 icon-bg-6"></span>
                        <h3 class="my-3">Easy CRM Integration</h3>
                        <p>Integrate missed call solution with any leading CRM software to automatically incorporate customer data.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container ptb_100">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2>Features of Missed Call</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin" style="min-height:250px;">
                <!-- Blog Thumb -->
                <!-- <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_1.jpg" alt=""></a>
                </div> -->
                <!-- Blog Content -->
                <div class="blog-content">
                    <h3 class="blog-title my-3"><a href="#">IVR</a></h3>
                    <p>Answer every customer call with the IVR’s multi-level menu to establish immediate interaction with your customer.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin" style="min-height:250px;">
                <!-- Blog Thumb -->
                <!-- <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_2.jpg" alt=""></a>
                </div> -->
                <!-- Blog Content -->
                <div class="blog-content">
                    <h3 class="blog-title my-3"><a href="#">Automated Messages</a></h3>
                    <p>Integrate your social media missed call campaigns and send automated messages to the leads.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog" style="min-height:250px;">
                <!-- Blog Thumb -->
                <!-- <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_3.jpg" alt=""></a>
                </div> -->
                <!-- Blog Content -->
                <div class="blog-content">
                    <h3 class="blog-title my-3"><a href="#">Toll-free Missed Call Numbers</a></h3>
                    <p>Expand your customer base by enabling toll-free miss call numbers to boost your marketing campaigns.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin" style="min-height:250px;">
                <!-- Blog Thumb -->
                <!-- <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_4.jpg" alt=""></a>
                </div> -->
                <!-- Blog Content -->
                <div class="blog-content">
                    <h3 class="blog-title my-3"><a href="#">Real-time Updates</a></h3>
                    <p>Any calls received on the missed call number gets updated with real-time alerts to never miss a customer call.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin" style="min-height:250px;">
                <!-- Blog Thumb -->
                <!-- <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_5.jpg" alt=""></a>
                </div> -->
                <!-- Blog Content -->
                <div class="blog-content">
                    <h3 class="blog-title my-3"><a href="#">Analytics Dashboard</a></h3>
                    <p>Track call center performance and caller trends, through end-to-end monitoring, to drive valuable business insights with analytics dashboards.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog" style="min-height:250px;">
                <!-- Blog Thumb -->
                <!-- <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_6.jpg" alt=""></a>
                </div> -->
                <!-- Blog Content -->
                <div class="blog-content">
                    <h3 class="blog-title my-3"><a href="#">Call Automatically Disconnects</a></h3>
                    <p>The calls automatically gets disconnected after a ring and the agent can call back instantly.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>