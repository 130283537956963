<template>
    <div class="row">
        <section class="ptb_100 row justify-content-center align-items-center">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog res-margin" style="min-height:250px;">
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <!-- <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">Jassica William</a></li>
                            <li><a href="#">Feb 05, 2020</a></li>
                        </ul> -->
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="/promotional-sms">Promotional SMS</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog" style="min-height:250px;">
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <!-- <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">John Doe</a></li>
                            <li><a href="#">Mar 05, 2020</a></li>
                        </ul> -->
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="transactional-sms">Transactional SMS</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>