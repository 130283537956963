<template>
    <div class="row">
        <section class="section about-area ptb_100">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-12 col-lg-6">
                        <!-- About Thumb -->
                        <div class="about-thumb text-center">
                            <img src="assets/img/about_vidyatra.jpg" alt="Cheap Bulk SMS">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <!-- About Content -->
                        <div class="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                            <h2 class="mb-3">What is Promotional SMS?</h2>
                            <p></p><br>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="pricing" class="section price-plan-area overflow-hidden ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-8">
                    <div class="row price-plan-wrapper">
                        <div class="col-12 col-md-6">
                            <!-- Single Price Plan -->
                            <div class="single-price-plan color-1 bg-hover active hover-top text-center p-5">
                                <!-- Plan Title -->
                                <div class="plan-title mb-2 mb-sm-3">
                                    <h3 class="mb-2">Basic</h3>
                                    <p>Message price excludes GST. (18% GST applicable on bill).</p>
                                </div>
                                <!-- Plan Price -->
                                <div class="plan-price pb-2 pb-sm-3">
                                    <span class="color-primary fw-7"></span>
                                    <span class="h1 fw-7"> 10,000</span>
                                    <sub class="validity text-muted fw-5"> SMS</sub>
                                </div>
                                <!-- Plan Description -->
                                <div class="plan-description">
                                    <ul class="plan-features">
                                        <li class="py-2">22 Paisa / SMS</li>
                                        <li class="py-2">99% Delivery</li>
                                        <li class="py-2">24/7 Support</li>
                                        <li class="py-2">Delivery Reports</li>
                                        <!-- <li class="py-2 text-muted">Delivery Reports</li> -->
                                    </ul>
                                </div>
                                <!-- Plan Button -->
                                <div class="plan-button">
                                    <a href="#" class="btn btn-bordered active mt-3">Buy Now</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mt-4 mt-md-0">
                            <!-- Single Price Plan -->
                            <div class="single-price-plan color-2 bg-hover active hover-top text-center p-5">
                                <!-- Plan Title -->
                                <div class="plan-title mb-2 mb-sm-3">
                                    <h3 class="mb-2">Pro <sup><span class="badge badge-pill badge-warning ml-2"></span></sup></h3>
                                    <p>Message price excludes GST. (18% GST applicable on bill).</p>
                                </div>
                                <!-- Plan Price -->
                                <div class="plan-price pb-2 pb-sm-3">
                                    <span class="color-primary fw-7"></span>
                                    <span class="h1 fw-7">1 Lakh</span>
                                    <sub class="validity text-muted fw-5"> SMS</sub>
                                </div>
                                <!-- Plan Description -->
                                <div class="plan-description">
                                    <ul class="plan-features">
                                        <li class="py-2">14 Paisa / SMS</li>
                                        <li class="py-2">99% Delivery</li>
                                        <li class="py-2">24/7 Support</li>
                                        <li class="py-2">Delivery Reports</li>
                                    </ul>
                                </div>
                                <!-- Plan Button -->
                                <div class="plan-button">
                                    <a href="#" class="btn btn-bordered active mt-3">Buy Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center pt-5">
                <div class="col-12">
                    <!-- FAQ Content -->
                    <div class="faq-content">
                        <!-- sApp Accordion -->
                        <div class="accordion" id="sApp-accordion">
                            <div class="row justify-content-center">
                                <div class="col-12 col-md-6">
                                    <!-- Single Card -->
                                    <div class="card border-0">
                                        <!-- Card Header -->
                                        <div class="card-header bg-inherit border-0 p-0">
                                            <h2 class="mb-0">
                                                <button class="btn px-0 py-3" type="button">
                                                    How Payment is made?
                                                </button>
                                            </h2>
                                        </div>
                                        <!-- Card Body -->
                                        <div class="card-body px-0 py-3">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <!-- Single Card -->
                                    <div class="card border-0">
                                        <!-- Card Header -->
                                        <div class="card-header bg-inherit border-0 p-0">
                                            <h2 class="mb-0">
                                                <button class="btn px-0 py-3" type="button">
                                                    What is Extra Days?
                                                </button>
                                            </h2>
                                        </div>
                                        <!-- Card Body -->
                                        <div class="card-body px-0 py-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <p class="text-body text-center pt-4 fw-5">Haven't find suitable answer? <a href="#">Tell us what you need.</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>