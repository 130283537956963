<template>
    <section class="section our-goal ptb_100">
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-12 col-lg-6">
                    <!-- Goal Content -->
                    <div class="goal-content section-heading text-center text-lg-left pr-md-4 mb-0">
                        <h2 class="mb-3">Our Goal</h2>
                        <p>Welcome to Vidyatra, your number one source for School ERP System. We're dedicated
                           to giving you the very best of ERP system, with a focus on Mobile Application For 
                           Parents, Students and School .</p>
                        <p>Founded in 2010 by Rekha Kumari, Vidyatra has come a long way from its beginnings 
                           in Delhi, When Rekha Kumari first started out. Vidyatra offers you AI enabled world's
                           most advanced ERP system For schools. We now serve customers all over India, and are 
                           thrilled that we're able to take our mission Internationally.</p>
                        <p>We hope you enjoy our ERP as much as we enjoy offering them to you. If you have any 
                           questions or comments, please don't hesitate to contact us.</p><br>
                        <p>Sincerely,</p>
                        <p>Rekha Kumari<br>MD</p>
                        <!-- <a href="#" class="btn btn-bordered mt-4">Read More</a> -->
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- Goal Thumb -->
                    <div class="goal-thumb mt-5 mt-lg-0">
                        <img src="assets/img/about_thumb_2.jpg" alt="about vidyatra school management software, about vidyatra mobile application">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>