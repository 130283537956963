<template>
    <div class="row">
        <section class="section about-area ptb_100">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-12 col-lg-6">
                        <!-- About Thumb -->
                        <div class="about-thumb text-center">
                            <img src="assets/img/chatbot.jpg" alt="chatbot voicebot service">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <!-- About Content -->
                        <div class="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                            <h2 class="mb-3">What is a ChatBot / VoiceBot solution?</h2>
                            <p>Conversational AI-powered Bots let you do more than just converse. Automate conversations for support, lead generation & more with ChatBots and VoiceBots. Built to give omnichannel experience through Website, WhatsApp and virtual assistant AI-technologies like Google Assistant & Alexa.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2>Benefits of ChatBot/VoiceBot Solution</h2>
                        <p class="d-none d-sm-block mt-4">Automate engagement and never let a query go unanswered.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-smartphone color-4 icon-bg-4"></span>
                        <h3 class="my-3">Be Available 24x7</h3>
                        <p>Improve Customer Experience & answer every query 24x7.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-monitoring color-2 icon-bg-2"></span>
                        <h3 class="my-3">Provide Multilingual Support</h3>
                        <p>Resolve customer queries in multiple languages.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-rocket-launch color-1 icon-bg-1"></span>
                        <h3 class="my-3">Be Omnipresent</h3>
                        <p>Reach out to customers on Website, WhatsApp and virtual assistant AI-technologies like Google Assistant & Alexa.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-web color-3 icon-bg-3"></span>
                        <h3 class="my-3">Improve Lead Conversion Rate</h3>
                        <p>Assist customers over call/chat in filling forms & reduce drop-offs.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-email color-5 icon-bg-5"></span>
                        <h3 class="my-3">Schedule Follow-Ups</h3>
                        <p>Proactively follow up with customers and send reminders on WhatsAPP to prevent customer churn.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service p-4">
                        <span class="flaticon-affiliate color-6 icon-bg-6"></span>
                        <h3 class="my-3">Cost-Effective</h3>
                        <p>Affordable pay-as-you-go model. No CapEx or complicated licensing.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container ptb_100">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2>Features of Chatbots/Voicebots</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin" style="min-height:250px;">
                <div class="blog-content">
                    <h3 class="blog-title my-3"><a href="#">Easy to Set-up & Scale</a></h3>
                    <p>Set-up superior customer experience and scale as you go.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin" style="min-height:250px;">
                <div class="blog-content">
                    <h3 class="blog-title my-3"><a href="#">Manage Intents</a></h3>
                    <p>Create new intents, edit intents to add/change/delete user-journeys by simply importing/exporting an excel file or a URL.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog" style="min-height:250px;">
                <div class="blog-content">
                    <h3 class="blog-title my-3"><a href="#">Auto Bot Pop-up</a></h3>
                    <p>Enable Auto Bot pop-up to assist customers during form-filling & documentation.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin" style="min-height:250px;">
                <div class="blog-content">
                    <h3 class="blog-title my-3"><a href="#">Protect Sensitive Data</a></h3>
                    <p>Make your customers feel secure by masking their confidential information.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin" style="min-height:250px;">
                <div class="blog-content">
                    <h3 class="blog-title my-3"><a href="#">Customizable UI</a></h3>
                    <p>Change themes, add image/video message as welcome message, change Bot icon, enable sticky buttons for common queries and more.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog" style="min-height:250px;">
                <!-- Blog Thumb -->
                <!-- <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_6.jpg" alt=""></a>
                </div> -->
                <!-- Blog Content -->
                <div class="blog-content">
                    <h3 class="blog-title my-3"><a href="#">E-Mail Reports</a></h3>
                    <p>Configure daily/weekly/monthly mailing of reports on Chatbot usage.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>