<template>
    <section class="section breadcrumb-area overlay-dark d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Breamcrumb Content -->
                    <div class="breadcrumb-content d-flex flex-column">
                        <h3 style="font-size:30px;" class="breadcrumb text-white text-uppercase mb-3">About</h3>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a class="text-uppercase text-white" href="/">Home</a></li>
                            <!-- <li class="breadcrumb-item"><a class="text-uppercase text-white" href="#">Pages</a></li> -->
                            <li class="breadcrumb-item text-white active">About Us</li>
                        </ol>
                        <div class="welcome-intro">
                            <!-- Buttons -->
                            <div class="button-group">
                                <a href="/contact" class="btn btn-bordered-white" title="request trial for vidyatra">Request Trial</a>
                                <a href="/contact" class="btn btn-bordered-white d-none d-sm-inline-block" title="contact vidyatra customer care">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>