<template>
    <div class="blog">
        <ScrollupSection />
        <div class="main overflow-hidden">
            <section id="blog" class="section blog-area ptb_100">
                <div class="container">
                    <VideoPlatformDetails />
                    <!-- <Pagination /> -->
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import VideoPlatformDetails from './ProductDetails/VideoPlatformDetails'
// import Pagination from '../Blogs/Pagination/Pagination'

export default {
    name: 'BlogThreeColumn',
    components: {
        VideoPlatformDetails
        // Pagination
    }
}
</script>

<style>

</style>