<template>
    <article class="single-blog-details">
        <!-- Blog Content -->
        <div class="blog-content digimax-blog p-3">
            <!-- Meta Info -->
            <div class="meta-info d-flex flex-wrap align-items-center py-2">
                <ul>
                    <li class="d-inline-block p-2"><a href="#">Vidyatra</a></li>
                    <li class="d-none d-md-inline-block p-2"><a href="#">05 Feb, 2020</a></li>
                </ul>
            </div>
            <!-- Blog Details -->
            <div class="blog-details">
                <p class="d-none d-sm-block">By signing this Agreement, the School is agreeing to be bound by the following terms and conditions ("Terms of Service"). The Service provider reserves the right to update and change the Terms of Service from time to time without notice. Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to the Terms of Service.</p>
                <p class="d-block d-sm-none">Continued use of the Service after any such changes shall constitute your consent to such changes. Violation of any of the terms below will result in the termination of your Account. While service provider prohibits such conduct and Content on the Service, you understand and agree that the service provider cannot be responsible for the Content posted on the Service and you nonetheless may be exposed to such materials. You agree to use the Service at your own risk.</p>
                <h6 class="blog-title py-3"><a href="#">Terms Of Service</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>Please read this agreement ("TOS") carefully. By using or accessing any Service, you (as the "User") agree to the terms of this TOS. "Service" means the Vidyatra service.
                        If you do not agree, do not use this Service. This TOS is a legal agreement between you and Vidyatra This TOS governs the Service, and content available through it. It also governs support services (if any) available as part of your subscription.
                        If a separate or supplemental TOS appears when you access any element of the Service, its terms will control as to that element. Vidyatra reserves the right to update and change the Terms of Service from time to time without notice. 
                        Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to the Terms of Service. Continued use of the Service after any such changes shall constitute your consent to such changes.
                        You can review the most current version of the Terms of Service at any time at www.Vidyatra/terms. Violation of any of the terms below will result in the termination of your Account. 
                        While Vidyatra prohibits such conduct and Content on the Service, you understand and agree that Vidyatra cannot be responsible for the Content posted on the Service and you nonetheless may be exposed to such materials. You agree to use the Service at your own risk.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Account Terms</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>You must be a human. Accounts registered by “bots” or other automated methods are not permitted. You must provide your legal full name, a valid email address, and any other information requested in order to complete the signup process. Your login may only be used by one person – a single login shared by multiple people is not permitted. You may create separate logins for as many people as you would like.
                        You are responsible for maintaining the security of your account and password. Vidyatra cannot and will not be liable for any loss or damage from your failure to comply with this security obligation. You are responsible for all Content posted and activity that occurs under your account (even when Content is posted by others who have accounts under your account). One person or legal entity may not maintain more than one free account.
                        You may not use the Service for any illegal or unauthorized purpose. You must not, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Payment, Refunds, Upgrading and Downgrading Terms</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>You must represent a school or a group of schools (“School”) in order to qualify for the 30 days free trial period. Your School can fully utilize the 1-month free trial period to examine all features of the Service. If you need more time beyond the 1-month free trial period, contact Vidyatra and tell us why. We reserve the right to refuse or approve the extension request. 
                        After the free trial period has ended, your School may choose to terminate the account or continue on a paying account. Once your account is converted to a paying account, your free trial period is over. You will be billed for your first month immediately upon conversion The Service is billed in advance on a monthly basis. There will be no refunds or credits for partial months of service, upgrade/downgrade refunds, or refunds for months unused with an open account.
                        For other reasons not mentioned above, then refunds will be given at the discretion of the Company Management. Calculation of how much you are billed is based on the number of students enrolled in the Vidyatra student management feature on the bill date. Students whose records have been marked in the system as having left the school or as having been deleted do not count towards the student total. Monthly bills are payable in full 10 days after the bill date where checks are used to make payments.
                        You can cancel at any time. No further payments are required from you upon account cancellation. All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you shall be responsible for payment of all such taxes, levies, or duties. For any upgrade or downgrade in plan level, you will automatically be billed the new rate on your next billing cycle. Downgrading your Service may cause the loss of Content, features, or capacity of your Account. Vidyatra does not accept any liability for such loss.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Cancellation and Termination</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>You are solely responsible for properly cancelling your account, which can be done via email to cancel at Vidyatra. All of your Content will be immediately backed up upon cancellation and will be transferred to you. If you cancel the Service before the end of your current paid up month, your cancellation will take effect immediately and you will not be charged again.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Modifications to the Service and Prices</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>Vidyatra reserves the right at any time and from time to time to modify, temporarily or permanently, the Service (or any part thereof) with or without notice. Prices of all Services, including but not limited to monthly subscription plan fees to the Service, are subject to change upon 30 days notice from us. Such notice may be provided at any time by posting the changes to the Vidyatra site. Vidyatra shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Copyright and Content Ownership</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>We claim no intellectual property rights over the material you provide to the Service. Your profile and materials uploaded remain yours. Vidyatra does not pre-screen Content, but Vidyatra and its designee have the right (but not the obligation) in their sole discretion to refuse or remove any Content that is available via the Service. The look and feel of the Service is copyright©2019-2020 Vidyatra All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML/CSS, Flash pages or visual design elements without express written permission from Vidyatra
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">General Conditions</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>Technical support is only provided to paying account holders and is available via email and telephonic conversation. You understand that Vidyatra uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service. You must not modify, adapt or hack the Service or modify another website so as to falsely imply that it is associated with the Service, Vidyatra, or any other Vidyatra service. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service,
                        use of the Service, or access to the Service without the express written permission by Vidyatra. We may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service. Verbal, physical, written or other abuse (including threats of abuse or retribution) of any Vidyatra customer, employee, member, or officer will result in immediate account termination. You understand that the technical
                        processing and transmission of the Service, including your Content, may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. You must not upload, post, host, or transmit unsolicited email, SMSs, or “spam” messages. You must not transmit any worms or viruses or any code of a destructive nature. If your bandwidth significantly exceeds the average bandwidth usage (as determined solely by Vidyatra) of other Vidyatra customers, we reserve the right to immediately disable your account or throttle 
                        your file hosting until you can reduce your bandwidth consumption. Vidyatra does not warrant that (i) the service will meet your specific requirements, (ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the service will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by you through the service will meet your expectations, and (v) any errors in the Service will be corrected. You expressly understand and agree that Vidyatra shall not be liable for any direct, indirect,
                        incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Vidyatra has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the service; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of
                        any third party on the service; (v) or any other matter relating to the service. The failure of Vidyatra to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. The Terms of Service constitutes the entire agreement between you and Vidyatra and govern your use of the Service, superseding any prior agreements between you and Vidyatra (including, but not limited to, any prior versions of the Terms of Service). Questions about the Terms of Service should be sent to: mail@vidyatra.com
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">General Terms & Conditions for Online Payments</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>1. Once a User has accepted these Terms and Conditions, he/ she may register and avail the Services.
                        2. In these Terms and Conditions, the term “Charge Back” shall mean, approved and settled credit card or net banking purchase transaction(s) which are at any time refused, debited or charged back to merchant account (and shall also include similar debits to Payment Service Provider's accounts, if any) by the acquiring bank or credit card company for any reason whatsoever, together with the bank fees, penalties and other charges incidental thereto.
                        3. Server Slow Down/Session Timeout: In case the Website or Payment Service Provider’s webpage, that is linked to the Website, is experiencing any server related issues like ‘slow down’ or ‘failure’ or ‘session timeout’, the User shall, before initiating the second payment,, check whether his/her Bank Account has been debited or not and accordingly resort to one of the following options:
                        In case the Bank Account appears to be debited, ensure that he/ she does not make the payment twice and immediately thereafter contact the Institute via e-mail or any other mode of contact as provided by the Institute to confirm payment. In case the Bank Account is not debited, the User may initiate a fresh transaction to make payment. However, the User agrees that under no circumstances the Payment Gateway Service Provider shall be held responsible for such fraudulent/duplicate transactions and hence no claims should be raised to Payment Gateway Service Provider No communication received by the Payment Service Provider(s) in this regards shall be entertained by the Payment Service Provider(s).
                        4. The Institute and the Payment Service Provider(s) assume no liability whatsoever for any monetary or other damage suffered by the User on account of:
                        The delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Payment Gateway or Services in connection thereto; and/ or Any interruption or errors in the operation of the Payment Gateway.
                        5. The User agrees, understands and confirms that his/ her personal data including without limitation details relating to debit card/ credit card transmitted over the Internet may be susceptible to misuse, hacking, theft and/ or fraud and that the Institute or the Payment Service Provider(s) have no control over such matters.
                        6. Although all reasonable care has been taken towards guarding against unauthorized use of any information transmitted by the User, the Institute does not represent or guarantee that the use of the Services provided by/ through it will not result in theft and/or unauthorized use of data over the Internet.
                        7. The Institute, the Payment Service Provider(s) and its affiliates and associates shall not be liable, at any time, for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information contained on the Website.
                        8. The User will be required to login his/ her own User ID and Password, given by the Institute in order to register and/ or use the Services provided by Institute on the Website. By accepting these Terms and Conditions the User aggress that his/ her User ID and Password are very important pieces of information and it shall be the User’s own responsibility to keep them secure and confidential. In furtherance hereof, the User agrees to;
                            Choose a new password, whenever required for security reasons. Keep his/ her User ID & Password strictly confidential. Be responsible for any transactions made by User under such User ID and Password.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Debit/Credit Card, Bank Account Details</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>1. The User agrees that the debit/credit card details provided by him/ her for use of the aforesaid Service(s) must be correct and accurate and that the User shall not use a debit/ credit card, that is not lawfully owned by him/ her or the use of which is not authorized by the lawful owner thereof. The User further agrees and undertakes to provide correct and valid debit/credit card details.
                        2. The User may pay his/ her fees to the Institute by using a debit/credit card or through online banking account. The User warrants, agrees and confirms that when he/ she initiates a payment transaction and/or issues an online payment instruction and provides his/ her card / bank details:
                        The User is fully and lawfully entitled to use such credit / debit card, bank account for such transactions; The User is responsible to ensure that the card/ bank account details provided by him/ her are accurate; The User is authorizing debit of the nominated card/ bank account for the payment of fees selected by such User along with the applicable Fees. The User is responsible to ensure sufficient credit is available on the 
                        nominated card/ bank account at the time of making the payment to permit the payment of the dues payable or the bill(s) selected by the User inclusive of the applicable Fee.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Payment Gateway Disclaimer</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>The Service is provided in order to facilitate access to view and pay Fees online. The Institute or the Payment Service Provider(s) do not make any representation of any kind, express or implied, as to the operation of the Payment Gateway other than what is specified in the Website for this purpose. By accepting/ agreeing to these Terms and Conditions, the User expressly agrees that 
                        his/ her use of the aforesaid online payment Service is entirely at own risk and responsibility of the User.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Cancellation Policy</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>Once the User will be registered and paid the Fee through online Payment Gateway. He/She will not be able to cancel the transaction in any circumstances. No Payment will be refunded to the User.
                    </p>
                </blockquote>
                <h6 class="blog-title py-3"><a href="#">Payment Refund Policy for Online Payments</a></h6>
                <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                    <p>Refund for Charge Back Transaction: In the event there is any claim for/ of charge back by the User for any reason whatsoever, such User shall immediately approach the Institute with his/ her claim details and claim refund from the Institute alone. Such refund (if any) shall be effected only by the Institute via payment gateway or any other means as the Institute deems appropriate. 
                        No claims for refund/ charge back shall be made by any User to the Payment Service Provider(s) and in the event such claim is made it shall not be entertained.
                    </p>
                </blockquote>
                <p class="d-none d-sm-block">Refund for fraudulent/duplicate transaction(s):</p>
                <p class="d-none d-sm-block">The User shall directly contact the Institute for any fraudulent transaction(s) on account of misuse of Card/ Bank details by a fraudulent individual/party and such issues shall be suitably addressed by the Institute alone in line with their policies and rules.</p>
            </div>
        </div>
    </article>
</template>

<script>
export default {

}
</script>

<style>

</style>