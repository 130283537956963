<template>
    <section class="section promo-area ptb_100">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 res-margin">
                    <!-- Single Promo -->
                    <div class="single-promo grad-hover text-center p-5">
                        <!-- Promo Wrapper -->
                        <div class="promo-wrapper">
                            <h3 class="mb-3">Zero to Hero</h3>
                            <!-- <p>test<strong>test</strong></p> -->
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 res-margin">
                    <!-- Single Promo -->
                    <div class="single-promo grad-hover active text-center p-5">
                        <!-- Promo Wrapper -->
                        <div class="promo-wrapper">
                            <h3 class="mb-3">Dedicated Support</h3>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Promo -->
                    <div class="single-promo grad-hover text-center p-5">
                        <!-- Promo Wrapper -->
                        <div class="promo-wrapper">
                            <h3 class="mb-3">100% Up-time</h3>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>