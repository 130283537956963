<template>
    <div class="row">
        <section class="section about-area ptb_100">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-12 col-lg-6">
                        <!-- About Thumb -->
                        <div class="about-thumb text-center">
                            <img src="assets/img/about_vidyatra.jpg" alt="school managemnet software erp services">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <!-- About Content -->
                        <div class="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                            <h2 class="mb-3">What is an ERP ?</h2>
                            <p></p><br>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/mobileApp.jpg" alt="school management software" style="min-height:200px;max-height:200px;min-width:100%;"></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Mobile Application</a></h3>
                    <p>Single App for Admin, Staff, Parents, Push notifications, homework, live classes.</p>
                    <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/reports.png" alt="erp software for schools" style="min-height:200px;max-height:200px;min-width:100%;"></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Reports</a></h3>
                    <p>Generate Dynamic Reports on a single click with user chosen filters and columns.</p>
                    <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/reportCard.jpg" alt="management software for schools" style="min-height:200px;max-height:200px;min-width:100%;"></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Report Cards</a></h3>
                    <p>Generate Dynamic Reports on a single click with user chosen filters and columns.</p>
                    <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/hr.jpg" alt="fee collection software for schools" style="min-height:200px;max-height:200px;min-width:100%;"></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">HR & Payroll</a></h3>
                    <p>Leave application and approvals, Generate Salary slips, ESI and tax calculations.</p>
                    <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/smartAttendance.png" alt="fee software for coaching centers" style="min-height:200px;max-height:200px;min-width:100%;"></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Smart Attendance</a></h3>
                    <p>Attendance on mobile app or biometric machines, Attendance Reports.</p>
                    <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/communication.jpg" alt="top school management software" style="min-height:200px;max-height:200px;min-width:100%;"></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Communicaton</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/exam.png" alt="best school management software" style="min-height:200px;max-height:200px;min-width:100%;"></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Exams</a></h3>
                    <p>Online Exams, instant Result and reports, manage marks and report card generation.</p>
                    <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/accounts.jpg" alt="best coaching software" style="min-height:200px;max-height:200px;min-width:100%;"></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Accounting</a></h3>
                    <p>Fee Collection, Payments, Reports and Ledger Generation.</p>
                    <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/inventory.jpg" alt="top coaching software" style="min-height:200px;max-height:200px;min-width:100%;"></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Inventory</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>