<template>
    <header id="header">
        <!-- Navbar -->
        <nav data-aos="zoom-out" data-aos-delay="800" class="navbar navbar-expand">
            <div class="container header">
                <!-- Navbar Brand-->
                <a class="navbar-brand" href="/">
                    <img class="navbar-brand-regular" src="assets/img/logo-white.png" alt="vidyatra-logo">
                    <img class="navbar-brand-sticky" src="assets/img/logo.png" alt="sticky vidyatra-logo">
                </a>
                <div class="ml-auto"></div>
                <!-- Navbar -->
                <!-- <ul class="navbar-nav items">
                    <li class="nav-item">
                        <a href="/" class="nav-link" title="Home">Home</a>
                    </li>
                </ul> -->
                <ul class="navbar-nav icons">
                    <li class="nav-item social">
                        <a href="/" class="nav-link" title="vidyatra"><i class="fa fa-home"></i></a>
                    </li>
                </ul>
                <ul class="navbar-nav items">
                    <li class="nav-item dropdown">
                        <a class="nav-link" href="#" title="vidyatra erp software">Products <i class="fas fa-angle-down ml-1"></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="/erp-services" class="nav-link" title="vidyatra erp services">ERP Services</a></li>
                            <li class="nav-item"><a href="/video-platform" class="nav-link" title="vidyatra video platform">Video Platform</a></li>
                            <li class="nav-item"><a href="/ivr-services" class="nav-link" title="vidyatra ivr services">IVR Services</a></li>
                            <li class="nav-item"><a href="/toll-free-number" class="nav-link" title="vidyatra toll free number">Toll Free Number</a></li>
                            <!-- <li class="nav-item"><a href="/promotional-sms" class="nav-link" title="vidyatra promotional sms">Promotional SMS</a></li> -->
                            <li class="nav-item"><a href="/chatbot-voicebot" class="nav-link" title="vidyatra chatbot voicebot">ChatBot/VoiceBot</a></li>
                            <li class="nav-item"><a href="/missed-call" class="nav-link" title="vidyatra missed call">Missed Call</a></li>
                            <li class="nav-item"><a href="/messaging-service" class="nav-link" title="vidyatra transactional sms">Messaging Service</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link" href="#" title="vidyatra erp software">Company <i class="fas fa-angle-down ml-1"></i></a>
                        <ul class="dropdown-menu">
                            <!-- <li class="nav-item"><a href="/pricing" class="nav-link" title="vidyatra school management software recharge plans">Recharge Plans</a></li> -->
                            <li class="nav-item"><a href="/core-team" class="nav-link" title="vidyatra core team">Core Team</a></li>
                            <li class="nav-item"><a href="/about" class="nav-link" title="about vidyatra">About</a></li>
                            <li class="nav-item"><a href="/WhyUs" class="nav-link" title="why use vidyatra school management software">Why Vidyatra?</a></li>
                            <li class="nav-item"><a href="/privacy-policy" class="nav-link" title="vidyatra privacy policy">Privacy Policy</a></li>
                            <li class="nav-item"><a href="/terms-of-use" class="nav-link" title="vidyatra terms of use">Terms Of Use</a></li>
                            <li class="nav-item"><a href="/terms-and-conditions" class="nav-link" title="vidyatra terms and conditions">Terms & Conditions</a></li>
                        </ul>
                    </li>
                </ul>
                <!-- Navbar Icons -->
                <ul class="navbar-nav icons">
                    <li class="nav-item social">
                        <a href="https://www.facebook.com/VidyatraSystems/" target="_blank" class="nav-link" title="vidyatra on facebook"><i class="fab fa-facebook-f"></i></a>
                    </li>
                    <li class="nav-item social">
                        <a href="https://twitter.com/vidyatra" target="_blank" class="nav-link" title="vidyatra on twitter"><i class="fab fa-twitter"></i></a>
                    </li>
                </ul>

                <!-- Navbar Toggler -->
                <ul class="navbar-nav toggle">
                    <li class="nav-item">
                        <a href="#" class="nav-link" data-toggle="modal" data-target="#menu">
                            <i class="fas fa-bars toggle-icon m-0"></i>
                        </a>
                    </li>
                </ul>

                <!-- Navbar Action Button -->
                <ul class="navbar-nav action">
                    <li class="nav-item ml-3">
                        <a href="/contact" class="btn ml-lg-auto btn-bordered-white" title="contact"><i class="fas fa-paper-plane contact-icon mr-md-2"></i>Contact Us</a>
                    </li>
                </ul>
                <ul class="navbar-nav items">
                    <li class="nav-item">
                        <a href="https://erp.vidyatra.com" class="nav-link" title="login">Login</a>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
export default {

}
</script>

<style>

</style>