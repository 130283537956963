<template>
    <footer class="section footer-area dark-bg">
        <!-- Footer Top -->
        <div class="footer-top ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-white text-uppercase mb-2">Vidyatra</h3>
                            <ul>
                                <li class="py-2"><a class="text-white-50" href="#" title="vidyatra">Testimonials</a></li>
                                <li class="py-2"><a class="text-white-50" href="#" title="careers in vidyatra">Careers</a></li>
                                <li class="py-2"><a class="text-white-50" href="#" title="vidyatra affiliate program">Affiliate Program</a></li>
                                <li class="py-2"><a class="text-white-50" href="#" title="vidyatra faqs">Frequently Asked</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-white text-uppercase mb-2">Services</h3>
                            <ul>
                                <li class="py-2"><a class="text-white-50" href="#" title="vidyatra faqs">ERP Services</a></li>
                                <li class="py-2"><a class="text-white-50" href="#" title="vidyatra faqs">Video Platform</a></li>
                                <li class="py-2"><a class="text-white-50" href="/terms-and-conditions" title="vidyatra terms and conditions">IVR Services</a></li>
                                <li class="py-2"><a class="text-white-50" href="/terms-and-conditions" title="vidyatra terms and conditions">Toll Free Number</a></li>
                                <li class="py-2"><a class="text-white-50" href="/privacy-policy" title="vidyatra privacy policy">Transactional SMS</a></li>
                                <li class="py-2"><a class="text-white-50" href="/terms-of-use" title="vidyatra terms of use">Promotional SMS</a></li>
                                <li class="py-2"><a class="text-white-50" href="/terms-of-use" title="vidyatra terms of use">ChatBot/VoiceBot</a></li>
                                <li class="py-2"><a class="text-white-50" href="/terms-of-use" title="vidyatra terms of use">Missed Call</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-white text-uppercase mb-2">Company</h3>
                            <ul>
                                <li class="py-2"><a class="text-white-50" href="/about" title="about vidyatra">Company Profile</a></li>
                                <li class="py-2"><a class="text-white-50" href="/terms-and-conditions" title="vidyatra terms and conditions">Terms &amp; Conditions</a></li>
                                <li class="py-2"><a class="text-white-50" href="/privacy-policy" title="vidyatra privacy policy">Privacy Policy</a></li>
                                <li class="py-2"><a class="text-white-50" href="/terms-of-use" title="vidyatra terms of use">Terms Of Use</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-white text-uppercase mb-2">Follow Us</h3>
                            <!-- <p class="text-white-50 mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, quae.</p> -->
                            <!-- Social Icons -->
                            <ul>
                                <li class="py-2"><a class="text-white-50" href="#" title="vidyatra office address">Ramphal Chowk, Dwarka Sector -7,</a></li>
                                <li class="py-2"><a class="text-white-50" href="#" title="vidyatra office address">New Delhi-110075.</a></li>
                                <li class="py-2"><a class="text-white-50" href="#" title="vidyatra office timings">Mon - Sat: 8:00 AM - 6:00 PM</a></li>
                                <li class="py-2"><a class="text-white-50" href="#" title="vidyatra support number">7027-03-7027</a></li>
                                <li class="py-2"><a class="text-white-50" href="#" title="vidyatra support email id">mail@vidyatra.com</a></li>
                            </ul>
                            <p class="mb-2">Stay Connected To Know About Our Cool Features.</p>
                            <!-- Social Icons -->
                            <ul class="social-icons list-inline pt-2">
                                <li class="list-inline-item px-1"><a class="text-white-50" href="https://www.facebook.com/VidyatraSystems/" target="_blank" title="join us on facebook"><i class="fab fa-facebook"></i></a></li>
                                <li class="list-inline-item px-1"><a class="text-white-50" href="https://twitter.com/vidyatra" target="_blank" title="join us on twitter"><i class="fab fa-twitter"></i></a></li>
                                <li class="list-inline-item px-1"><a class="text-white-50" href="#" title="join us on linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                                <li class="list-inline-item px-1"><a class="text-white-50" href="#" title="join us on instagram"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer Bottom
        <div class="footer-bottom dark-bg">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            <div class="copyright-left text-white-50">&copy; Copyrights 2020 Digimax All rights reserved.</div>
                            <div class="copyright-right text-white-50">Made with <i class="fas fa-heart color-2"></i> By <a class="text-white-50" href="#">Themeland</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Footer Bottom -->
        <div class="footer-bottom bg-grey">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Copyright Area -->
                        <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            <!-- Copyright Left -->
                            <div class="copyright-left">&copy; Copyrights 2021 All rights reserved. Acadbee Systems Pvt Ltd</div>
                            <!-- Copyright Right -->
                            <div class="copyright-right"><i class="fas fa-heart"></i> By <a href="#" title="vidyatra best, top school management sofware website">Vidyatra</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>